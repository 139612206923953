import React, { useEffect, useState, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import { quoteContext } from '../../../provider';

import '../../assets/styles/pages/quote/step-two.scss';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import ProgressBar from '../../components/quotes/progress-bar';
import QuoteNav from '../../components/quotes/quote-nav';
import BackArrow from '../../images/quote/icon-arrow-left.inline.svg';

/* global window */
const QuoteStepTwo = () => {
  const [description, setDescription] = useState([]);
  const [atFault, setAtFault] = useState('');
  let { quoteForm } = useContext(quoteContext);

  useEffect(() => {
    setDescription(quoteForm.description);
  }, [quoteForm]);

  const handleInputChange = (event) => {
    const val = event.target.value;
    setDescription(val);
  };

  const handleAtFaultChange = (event) => {
	const val = event.target.value;
	setAtFault(val);
  };

  const handleSubmit = (event, context) => {
    event.preventDefault();
    context.addToQuoteForm({
      ...quoteForm,
      description: description,
	  atFault: atFault
    });
    navigate('/quote/step-three');
  };

  const hasDescription = () => {
    if (description) {
      return (description.length > 0);
    }

    return false;
  }

  return (
    <Layout wrapperClass="quote-container" hideHeaderFooter={true}>
      <quoteContext.Consumer>
        {context => (
          <React.Fragment>
            <ProgressBar step="2" />
            <SEO title="DingGo | Car Quote - Describe The Damage" description="Upload your photos and describe the damage to compare car repair quotes online, fast and free. Snap. Compare. Repair." />
            <div className="quote-wrapper step-two">
              <div className="container sm">
                <QuoteNav step="2" />
                <h1>Describe Damage</h1>
                <div className="intro">
                  <p>Please Include:</p>
                  <p>1. Location of damage (eg. front left door, back bumper, bonnet etc.)</p>
                  <p>2. Type of damage (eg. dent, scratch, paint damage etc.)</p>
                </div>
                <form onSubmit={(e) => { handleSubmit(e, context); }}>
                  <textarea placeholder="Damage description examples:&#10;Scratch on front bumber, paint damaged&#10;Dent on back left door, no paint damaged" onChange={(e) => { handleInputChange(e); }} value={description}></textarea>
				  <div className="form-group">
				    <label>Do you believe you were at fault in this incident?</label>
				    <select className="form-control" id="atFault" name="atFault" onChange={(e) => { handleAtFaultChange(e); }} value={atFault}>
				      <option>Unsure</option>
					  <option>Yes</option>
					  <option>No</option>
				    </select>
				  </div>
				  <div className="form-btn-wrapper">
                    <Link to="/quote/step-one" className="arrow-cta"><BackArrow />Back</Link>
                    <button className={`btn ${hasDescription() ? '' : 'disable'}`} type="submit" id="button-click-to-step-3">Next</button>
                  </div>
                </form>
              </div>
            </div>
          </React.Fragment>
        )}
      </quoteContext.Consumer>
    </Layout>
  );
};

export default QuoteStepTwo;
